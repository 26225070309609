import mapboxgl from 'mapbox-gl'
import { LegacyRef, MutableRefObject, useContext, useEffect, useRef } from 'react'
import { styles } from './RoutesMapViewStyles'
import { useDeliveryRoutesByStore } from '../../../../queries/DeliveryRoutes/useDeliveryRoutesByStore';
import { RouteBuilderContext } from '../../RouteBuilder';
import { addStoreMarker, createMarkerElement } from '../../../../components/MapView/MapMarkers';
import { StoreContext } from '../../../../utilities/contexts/StoreContext';
import { DeliveryRoute } from '../../../../utilities/types/DeliveryRouteTypes';

interface Markers {
  invoiceNo: string
  marker: mapboxgl.Marker
}

const getMarkerElem = () => {
  return createMarkerElement({ className: `marker` });
}

const MapView =({showAutoDispatch}:{showAutoDispatch:boolean})  => {
  const mapContainer = useRef() as MutableRefObject<HTMLElement>
  const { filteredRoutes } = useContext(RouteBuilderContext);
  const map = useRef<mapboxgl.Map | undefined>(undefined);
  const { storeAddress } = useContext(StoreContext)
  const mapMarkers = useRef<Markers[]>([])
  const storeMarker = useRef<mapboxgl.Marker | null>(null);
  const storeLocation: [number,  number] = [storeAddress?.longitude as number, storeAddress?.latitude as number]

  const isAutoDispatch = (route: DeliveryRoute):boolean => {
    return route.stops?.some(stop => 
      stop.invoices?.some(invoice => invoice.autoDispatch)
    );
  }

  const {
    data: routes,
  } = useDeliveryRoutesByStore()

  useEffect(() => {
    if (routes && map.current) {
      if (map !== undefined) {
        mapMarkers.current.forEach(marker => {
          marker.marker.remove();
        });
        mapMarkers.current = [];
        const filteredRoutes = showAutoDispatch ? routes : routes?.filter(isAutoDispatch);
        getGeolocationCoordinates(filteredRoutes);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routes, filteredRoutes]);

  const getGeolocationCoordinates = (routes: any[]) => {
    let markers: any[] = [];
    let bounds = new mapboxgl.LngLatBounds();
    //store Marker
    if (storeMarker.current) storeMarker.current.remove();
    const newStoreMarker = addStoreMarker('40px').setLngLat(storeLocation);
    if (map.current) {
      newStoreMarker.addTo(map.current);
      storeMarker.current = newStoreMarker;
      bounds.extend(storeLocation)
    }

    routes.map(async (route: any) => {
      if (route && route.stops && route.stops.length > 0 && route.stops[0] && route.stops[0].invoices && route.stops[0].invoices.length > 0) {
        let invoice = route.stops[0].invoices[0];
        if (!invoice.deliveryStatus) {
          const coordinate: [number, number] = [invoice.longitude, invoice.latitude];//await getMapCoordinate(invoice)
          if (coordinate) {
            const obj: mapboxgl.MarkerOptions = {};
            obj.color = '#FFE395'

            const isInvoiceDisplayed = filteredRoutes.includes(route.routeId)
            if (map.current && isInvoiceDisplayed) {
              const el = getMarkerElem();
              const marker = new mapboxgl.Marker(el)
                .setLngLat(coordinate)
                .addTo(map.current);
              if (marker) {
                mapMarkers.current = [...mapMarkers.current, { invoiceNo: invoice.invoiceNumber, marker: marker }]
                markers.push({ invoiceNo: invoice.invoiceNumber, marker })
              }
            }
            if (coordinate[0] !== 0 && coordinate[1] !== 0) {
              bounds.extend(coordinate);
            }
          }
        }
      }
      return route;
    });

    if (map.current && !bounds.isEmpty()) {
      map.current.fitBounds(bounds, { padding: 50, duration: 0 });
    }
  }

  useEffect(() => {
    if (map.current) return
    const [usCenterLng, usCenterLat] = [-98.5795, 39.8283];
    map.current = new mapboxgl.Map({
      container: mapContainer.current as HTMLElement,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: (storeLocation) ? storeLocation : [usCenterLng, usCenterLat],
      zoom: 4
    })
    const navControl = new mapboxgl.NavigationControl()
    map.current.addControl(navControl, "top-right")
  })
  return (
    <div style={styles.mapContainer} ref={mapContainer as LegacyRef<HTMLDivElement>} >
    </div>
  )
}

export { getMarkerElem }
export default MapView
