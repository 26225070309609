import { Box, Button, Grid, IconButton, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { customerDetailsStyles } from './CustomerDetailsStyles'
import { ReactComponent as ArrowBack } from '../../../assets/arrow_back.svg'
import { ReactComponent as StarIcon } from '../../../assets/star.svg'
import { useContext, useState } from "react";
import { CustomerDetails, CustomerService, CustomerAddressPayload, formatARNumber, formatPhoneNumber, validateLatitude, validateLongitude, AddressInfo, CustomerAddressResponse, DeliveryGeoCodes, SlaUpdatePayload } from "../../../utilities/services/CustomerService/CustomerService";
import CustomerAddressView from "./CustomerAddress/CustomerAddressView";
import CustomerAddress from "./CustomerAddress/CustomerAddress";
import DropPinMap from "../CustomerDetailsList/DropPinMap";
import { SnackbarContext } from "../../../utilities/contexts/SnackbarContext";
import { LanguageContext } from "../../../utilities/contexts/LanguageContext";
import language from "../../../language/language";
import { StoreContext } from "../../../utilities/contexts/StoreContext";
import React from "react";
import { PinDrop } from "@mui/icons-material";

interface InputProps {
    customerDetails: CustomerDetails | null | undefined,
    onBackPageClick: () => void
    onCustomerUpdate: (updatedAddress: CustomerAddressResponse | null, needsAddressRefetch: boolean, slaPayload: SlaUpdatePayload | null, customerId: string | null) => void
}
const CustomerDetailsView = ({ customerDetails, onBackPageClick, onCustomerUpdate }: InputProps) => {
    const { currentLanguage } = useContext(LanguageContext)
    const {currentStore} = useContext(StoreContext)
    const [serviceLevelEdit, setServiceLevelEdit] = useState<boolean>(false);
    const [addressEdit, setAddressEdit] = useState<number>(-1);
    const [lnglat, setLngLat] = useState<[number, number] | null>(null);
    const [blackPinLngLat, setBlackPinLngLat] = useState<[number, number] | null>(null);
    const { addSnack } = useContext(SnackbarContext)
    const serviceLevels = [
        { displayName: "PLATINUM", fill: '#F4F4F4' },
        { displayName: "GOLD", fill: '#FFE395' },
        { displayName: "SILVER", fill: '#ADADAD' },
        { displayName: "BRONZE", fill: '#E49600' },
        { displayName: "AUTODASH", fill: '#FF8488' }
    ]

    const getColorBasedOnServiceLevel = (serviceLevel: string) => {
        let colorCode = ''
        switch (true) {
          case serviceLevel === 'PLATINUM':
            colorCode = '#D6D6D6'
            break
          case serviceLevel === 'GOLD':
            colorCode = '#FFE395'
            break
          case serviceLevel === 'SILVER':
            colorCode = '#ADADAD'
            break
          case serviceLevel === 'BRONZE':
            colorCode = '#E49600'
            break
          case serviceLevel === 'AUTODASH':
                colorCode = '#FF8488'
                break
        }
        return colorCode
      }
    const [serviceLevelValue,setServiceLevelValue]=useState<string>(customerDetails?.customerInfo.priority || serviceLevels[2].displayName); //default service level is SILVER

    const updateCustomerAddress = async (customerAddress: CustomerAddressPayload) => {
        try {

            let needsRefetch : boolean = false;

            const currentCustomerAddress : AddressInfo | null = !!customerAddress.addressId ? 
                customerDetails?.addressInfo?.find((addressInfo: AddressInfo)  => addressInfo?.addressId === customerAddress?.addressId) ?? null
                : null;

            if((!!!customerAddress.deliveryGeocodes || customerAddress.deliveryGeocodes.length === 0) && !!currentCustomerAddress) {
                customerAddress.deliveryGeocodes = 
                    currentCustomerAddress.deliveryGeocodes?.map((deliveryGeocode: DeliveryGeoCodes) => {return {...deliveryGeocode, isDefault : "N"}}) ?? null
            }
            else if(!!customerAddress.deliveryGeocodes && customerAddress.deliveryGeocodes.length > 0){
                customerAddress.deliveryGeocodes[0].isDefault = "Y"

                const existingDGeocode = currentCustomerAddress?.deliveryGeocodes?.at(0)
                if(!!existingDGeocode){
                    customerAddress.deliveryGeocodes[0].pinId = existingDGeocode.pinId
                }
                else {
                    if(!!currentCustomerAddress) {
                        //No original delivery geocode found, so a new one will be created and we would want to know the new pin id, so calling refetch after this update query
                        needsRefetch = true
                    }
                }
            }

            customerAddress.phoneNumber = customerAddress.phoneNumber?.length === 11 ? customerAddress.phoneNumber : ''

            let updateAddress;
            if (!customerAddress.addressId) {
                //This invoice update will only work if the given customer object has a single AR number associated with it - I think we might have to switch to addressId (no other solution, other than sending all customerARNumbers - which will be a bigger query)
                updateAddress = await CustomerService.saveCustomer(
                    customerAddress, 
                    currentStore, 
                    customerDetails?.addressInfo ? customerDetails?.addressInfo.length === 2 : false, 
                    customerDetails?.addressInfo.find(address => !!address.addressId)?.addressId //there must be exactly one address with id, and we need this to update all relevant invoices 
                )
                onCustomerUpdate(updateAddress, false, null, null)
            } else {
                updateAddress = await CustomerService.updateCustomer(customerAddress, currentStore)
                onCustomerUpdate(customerAddress, needsRefetch, null, null)
            }
            addSnack({
                severity: 'success',
                message: 'Success! Your changes have been saved.',
                action: null,
                duration: 3000
            })
            setLngLat(null)
            setBlackPinLngLat(null)
            setAddressEdit(-1)
        } catch (e: any) {
            addSnack({
                severity: 'error',
                message: 'Error! Unable to save your changes.',
                action: null,
                duration: 3000
            })
        }
    }

    const updateSLA = async () => {
        try{
            let slaPayload: SlaUpdatePayload = {} as SlaUpdatePayload
            slaPayload.priority = serviceLevelValue
            slaPayload.updatedBy = currentStore
            await CustomerService.updateSlaChange(slaPayload,customerDetails?.customerInfo.customerId ?? 'NA')
            addSnack({
                severity: 'success',
                message: 'Success! Your changes have been saved.',
                action: null,
                duration: 3000
            })
            onCustomerUpdate(null, false, slaPayload, customerDetails?.customerInfo?.customerId ?? null)
            setServiceLevelEdit(false)
        } catch (e: any) {
            addSnack({
                severity: 'error',
                message: 'Error! Unable to save your changes.',
                action: null,
                duration: 3000
            })
        }
    }

    const serviceLevelMenuItems = serviceLevels.map((serviceLevel) => {
        return <MenuItem value={serviceLevel.displayName}>
            <Box sx={customerDetailsStyles.serviceLevel}>
                <StarIcon style={{ fill: serviceLevel.fill }} />
                <Typography>{serviceLevel.displayName}</Typography>
            </Box>
        </MenuItem>
    })

    const handleSLAChange = (event: SelectChangeEvent) => {
        const slvalue = event.target.value
        if(serviceLevelValue !== slvalue){
            setServiceLevelValue(slvalue) 
        }
      }

    const hasValidDefaultLocation = () => {
        const defaultLocation : AddressInfo | undefined = customerDetails?.addressInfo?.find(address => address?.isPreferredAddress === 'Y')
        
        return !!defaultLocation && !!defaultLocation.phoneNumber && (defaultLocation.phoneNumber.length === 10 || defaultLocation.phoneNumber.length === 11)
    }

    return <Grid container direction="column" padding={2} gap={2}>
        <Grid item display="flex">
            <IconButton sx={customerDetailsStyles.typoSmall} onClick={onBackPageClick}>
                <ArrowBack style={{ paddingRight: "8px" }} /> <div>
                    {(language as any)[currentLanguage].backToResults}
                </div>
            </IconButton>
        </Grid>
        <Grid>
            <Typography align="left" sx={customerDetailsStyles.typoHeader3}>{customerDetails?.customerInfo?.name}</Typography>
        </Grid>
        <Grid>
            <Typography align="left" sx={customerDetailsStyles.typoHeader5}>
                {(language as any)[currentLanguage].accountDetails}    
            </Typography>
        </Grid>
        <Grid container spacing={2} xs={12} sx={customerDetailsStyles.accountDetailsLayout}>
            <Grid xs={2}>
                <Typography sx={customerDetailsStyles.paragraphBold2}>
                    {(language as any)[currentLanguage].arNumber}
                </Typography>
                <Typography sx={customerDetailsStyles.paragraph2}>{formatARNumber(customerDetails?.customerInfo?.accountInfo?.[0]?.accountReceivableNumber?.[0] || '')}</Typography>
            </Grid>
            <Grid xs={2}>
                <Typography sx={customerDetailsStyles.paragraphBold2}>
                    {(language as any)[currentLanguage].phoneNumber}
                </Typography>
                <Typography sx={customerDetailsStyles.paragraph2}>{formatPhoneNumber(customerDetails?.customerInfo?.contactInfo?.[0].phoneNumber?.[0] || '')}</Typography>
            </Grid>
            <Grid xs={8}>
                <Typography sx={customerDetailsStyles.paragraphBold2}>
                    {(language as any)[currentLanguage].serviceLevel}
                    {!serviceLevelEdit && <Button variant="text"
                        sx={customerDetailsStyles.paragraph2Link}
                        onClick={() => {
                            setServiceLevelEdit(true)
                        }}
                    >
                        {(language as any)[currentLanguage].edit}
                    </Button>}
                </Typography>
                {!serviceLevelEdit && <Box sx={customerDetailsStyles.serviceLevel}>
                    <StarIcon style={{ fill: getColorBasedOnServiceLevel(serviceLevelValue) }}></StarIcon>
                    <Typography sx={customerDetailsStyles.paragraph2}>
                        {serviceLevelValue}</Typography>
                </Box>}
                {!!serviceLevelEdit &&
                    <Box sx={customerDetailsStyles.serviceLevel}>
                        <Select value={serviceLevelValue} 
                            onChange={handleSLAChange} 
                            error={serviceLevelValue === 'AUTODASH' && !hasValidDefaultLocation()}
                            style={customerDetailsStyles.slaSelect}
                        >
                            {serviceLevelMenuItems}
                        </Select>
                        <Button variant="text"
                            sx={customerDetailsStyles.paragraph2Link}
                            onClick={updateSLA}
                            disabled={serviceLevelValue === 'AUTODASH' && !hasValidDefaultLocation()}
                        >
                            {(language as any)[currentLanguage].save}
                        </Button>
                    </Box>
                }
                {serviceLevelValue === 'AUTODASH' && !hasValidDefaultLocation() && <Box style={customerDetailsStyles.slaError}>Select a default location for Autodash (Valid Phone Number is Required)</Box>}
            </Grid>
        </Grid>
        <Grid>
            <Typography align="left" sx={customerDetailsStyles.typoHeader5}>
                {(language as any)[currentLanguage].deliveryInformation}
            </Typography>
        </Grid>
        {customerDetails?.addressInfo?.map((addressInfo, addressIndex) => {
            let latVal : number | null = null
            let lngVal : number | null = null
            let redPin : [number, number] | null = null
            let pinLabel : string | null = null
            if (!!addressInfo?.geocodes && !!addressInfo.geocodes.latitude && !!addressInfo.geocodes.longitude) {
                latVal = validateLatitude(+addressInfo.geocodes.latitude)
                lngVal = validateLongitude(+addressInfo.geocodes.longitude)

                if(!!latVal && !!lngVal){
                    const redDropPin = addressInfo?.deliveryGeocodes?.find(delGeocode => delGeocode.isDefault === "Y" && !!delGeocode.latitude && !!delGeocode.longitude)
                    const validatedLat = !!redDropPin && validateLatitude(+redDropPin.latitude)
                    const validatedLong = !!redDropPin && validateLongitude(+redDropPin.longitude)
    
                    redPin = !!validatedLat && !!validatedLong ? [validatedLong, validatedLat] : null
                    if(!!redPin && !!redDropPin?.pinLabel && redDropPin.pinLabel !== ""){
                        pinLabel =  redDropPin.pinLabel
                    }
                }
            }
            return <Grid container display={'flex'} flexDirection={'row'} style={customerDetailsStyles.accountDetailsLayout}>
                <Grid xs={6} paddingRight={'20px'}>
                    {addressEdit !== addressIndex &&
                        <CustomerAddressView addressInfo={addressInfo} onEdit={()=>{
                            setAddressEdit(addressIndex)
                            !!redPin && setLngLat(redPin)
                            !!latVal && !!lngVal && setBlackPinLngLat([lngVal, latVal])
                        }}
                        index={addressIndex}
                        redPin = {redPin ?? null}
                        pinLabel = {pinLabel ?? null}
                        addressEdit = {addressEdit}
                        ></CustomerAddressView>
                    }
                    {addressEdit === addressIndex &&
                        <CustomerAddress customerInfo={customerDetails?.customerInfo} addressInfo={addressInfo} onSave={updateCustomerAddress}
                            onCancel={()=>{
                                customerDetails.addressInfo = customerDetails?.addressInfo.filter(info=>{
                                    return !!info.addressId;
                                })
                                setLngLat(null)
                                setBlackPinLngLat(null)
                                setAddressEdit(-1)
                            }} lngLat={lnglat}
                            setLngLat={setLngLat}
                            index={addressIndex}
                            pinLabel = {pinLabel ?? null}
                            blackPinLngLat={blackPinLngLat}
                            setBlackPinLngLat={setBlackPinLngLat}
                            ></CustomerAddress>
                    }
                </Grid>
                <Grid xs={6} height={"258px"}>
                    {(!!latVal && !!lngVal) || (!!blackPinLngLat && blackPinLngLat.length>1 && addressEdit === addressIndex)  ?
                        <DropPinMap lngLat={addressEdit === addressIndex ? lnglat : redPin ?? null} setLngLat={setLngLat} someAddress={
                            {
                                latitude: addressEdit === addressIndex && !!blackPinLngLat ? blackPinLngLat[1] : latVal ?? 0, 
                                longitude: addressEdit === addressIndex && !!blackPinLngLat ? blackPinLngLat[0] : lngVal ?? 0
                            }
                        } isDraggable={addressEdit === addressIndex}/>
                        :
                        <>{
                            <Box sx={customerDetailsStyles.noMap}>
                                <Box sx={customerDetailsStyles.noMapCard}>
                                    <PinDrop sx={customerDetailsStyles.noMapPin}/>
                                    <Typography sx={customerDetailsStyles.noMapText}>No Map Available</Typography>
                                </Box>
                            </Box>
                        }</>
                    }

                </Grid>
            </Grid>
        })}
        <Box display="flex" alignItems="start">
            {
                (addressEdit) < 0 &&
                <Button sx={customerDetailsStyles.paragraph2Link}
                    onClick={()=>{
                        const newAddress = {} as AddressInfo
                        //set isDefault to false for new address drop location
                        customerDetails?.addressInfo.push({...newAddress})
                        setAddressEdit((customerDetails?.addressInfo || []).length-1)
                    }}
                >
                    + {!!customerDetails?.addressInfo && customerDetails.addressInfo.length !== 0
                        ? (language as any)[currentLanguage].additionalLocation
                        : (language as any)[currentLanguage].addLocation
                    }
                </Button>
            }
            
        </Box>
    </Grid>
}

export default CustomerDetailsView;