import { GenericSuccessResponse } from "../DeliveryRouteService";
import axiosInstance from "../../helpers/AxiosHelper";

export type CustomerLookupPayload = {
    storeNumber: string,
    accountReceivableNumber: string,
    isFullARNbr: boolean
}

export type CustomerAddressPayload = {
    customerId: string,
    addressId?: string,
    addressType: string,
    addressLabel: string,
    line1: string,
    line2: string | null,
    city: string,
    state: string,
    zip: string,
    country: string,
    latitude: string,
    longitude: string,
    notes: string | null,
    phoneNumber: string,
    updatedBy: string,
    createdBy: string,
    isPreferredAddress: string,
    deliveryGeocodes: 
        {
            pinId?: string,
            pinLabel: string,
            latitude: string,
            longitude: string,
            isDefault: string
        }[] 
     | null
}

export type CustomerUpdateAddressPayload = {
    storeNumber: string,
    invoiceNumber: string
    invoiceDateTime: number
    customerAddressDetails: CustomerDetails
}

export type ConfirmAddressPayload = {
    storeNumber: string
    invoiceNumber: string
    invoiceDateTime: string
}

export type SlaUpdatePayload={
    priority:string
    updatedBy:string
}

export type APIResponse = {
    status: string
    message: string
    timestamp: string
}

export type CustomerAddressResponse = CustomerAddressPayload

export class CustomerService {
    static async getCustomerDetails(storeNumber: string, accountReceivableNumber: string, page=1, limit=25, isFullARNbr: boolean) : Promise<CustomerResponse> {
        const searchPayload: CustomerLookupPayload = {
            storeNumber: storeNumber,
            accountReceivableNumber: accountReceivableNumber,
            isFullARNbr: isFullARNbr
        }
        try {
            //sort by will be static to name as backend is not supporting.
            const response = await axiosInstance.post<CustomerResponse>(`/customer/commercial/lookup?page=${page}&limit=${limit}&sortOrder=asc&sortBy=name`, searchPayload);
            if (response.status === 200) {
                return response.data;
            }
            return Promise.reject(`[${response.status}]: ${response.data}`);
        } catch (e) {
            return Promise.reject(e)
        }
    }


    static async updateCustomer(updatePayload: CustomerAddressPayload, storeNumber: string) : Promise<GenericSuccessResponse> {
        try {
            const response = await axiosInstance.patch<GenericSuccessResponse>(`/customer/storeNumber/${storeNumber}/commercial/${updatePayload.customerId}/addresses/${updatePayload.addressId}`, updatePayload);
            if (response.status === 200) {
                return response.data;
            }
            return Promise.reject(`[${response.status}]: ${response.data}`);
        } catch (e) {
            return Promise.reject(e)
        }
    }

    static async updateCustomerAddressInfo(addressPayload: CustomerUpdateAddressPayload): Promise<APIResponse> {
        try {
            const response = await axiosInstance.patch<APIResponse>(`/invoice/v2/update/address`, addressPayload);
            if (response.status === 200) {
                return response.data;
            }
            return Promise.reject(`[${response.status}]: ${response.data}`);
        } catch (e) {
            return Promise.reject(e)
        }
    }
    
    static async saveCustomer(updatePayload: CustomerAddressPayload, storeNumber: string,isMultiLocUser: boolean, originalAddressId: string | undefined) : Promise<CustomerAddressResponse> {
        try {
            const response = await axiosInstance.post<CustomerAddressResponse>(`/customer/storeNumber/${storeNumber}/commercial/${updatePayload.customerId}/addresses?isSecondLocation=${isMultiLocUser}&originalAddressId=${originalAddressId}`, updatePayload);
            //This invoice update will only work if the given customer object has a single AR number associated with it - I think we might have to switch to addressId (no other solution, other than sending all customerARNumbers - which will be a bigger query)
            if (response.status === 200) {
                return response.data;
            }
            return Promise.reject(`[${response.status}]: ${response.data}`);
        } catch (e) {
            return Promise.reject(e)
        }
    }

    static async updateConfirmAddress(addressPayload: ConfirmAddressPayload[]): Promise<APIResponse> {
        try {
            const response = await axiosInstance.patch<APIResponse>(`/invoice/v2/confirm/address`, addressPayload);
            if (response.status === 200) {
                return response.data;
            }
            return Promise.reject(`[${response.status}]: ${response.data}`);
        } catch (e) {   
            return Promise.reject(e)
        }
    }

    static async updateSlaChange(slaUpdatePayload: SlaUpdatePayload,customerId:string) : Promise<APIResponse> {
        try {
            const response = await axiosInstance.patch<APIResponse>(`/customer/customerId/${customerId}/sla`, slaUpdatePayload);
            if (response.status === 200) {
                return response.data;
            }
            return Promise.reject(`[${response.status}]: ${response.data}`);
        } catch (e) {
            return Promise.reject(e)
        }
    }
}

export const transformAddressAPIResponse = (addressResponse: CustomerAddressResponse): AddressInfo => {
    return {
        addressId: addressResponse.addressId,
        addressLabel: addressResponse.addressLabel,
        addressType: addressResponse.addressType,
        addressline1: addressResponse.line1,
        addressline2: addressResponse.line2,
        city: addressResponse.city,
        state: addressResponse.state,
        zip: addressResponse.zip,
        country: addressResponse.country,
        deliveryGeocodes: addressResponse.deliveryGeocodes as DeliveryGeoCodes[],
        note: addressResponse.notes,
        phoneNumber: addressResponse.phoneNumber,
        geocodes: { latitude: addressResponse.latitude, longitude: addressResponse.longitude } as GeoCode,
        isPreferredAddress: addressResponse.isPreferredAddress
    } as AddressInfo
}

export type CustomerResponse = {
    commercialCustomerListInfo: CustomerDetails[],
    pageDetailsInfo: PageDetailsInfo,
    message: string
}

export default function emptyCustomerResponse() {
    return {
        
    } as CustomerResponse
}

export type CustomerInfo = {
    customerId: string,
    name: string,
    priority: string,
    accountInfo: AccountInfo[],
    contactInfo: ContactInfo[]

}

export type AccountInfo = {
    accountReceivableNumber: string[]
}

export type ContactInfo = {
    contactType: string,
    phoneNumber: string[]
}

export type GeoCode = {
    latitude: string,
    longitude: string
}

export type DeliveryGeoCodes = {
    latitude: string,
    longitude: string,
    pinId: string,
    pinLabel: string,
    isDefault: string
}

export type AddressInfo = {
    addressId: string,
    addressType: string,
    addressLabel: string,
    addressline1: string,
    addressline2: string,
    addressline3: string,
    city: string,
    state: string,
    zip: string,
    zipExt: string,
    country: string,
    geocodes: GeoCode,
    deliveryGeocodes: DeliveryGeoCodes[],
    note: string,
    phoneNumber: string | null
    isPreferredAddress: string
}

export type Preferences = {
    priorityPlus: boolean
}

export type PageDetailsInfo = {
    currentPage: number,
    nextPage: number,
    prevPage: number,
    pageSize: number,
    totalPages: number,
    totalRecords: number
}

export type CustomerDetails = {
    customerInfo: CustomerInfo,
    addressInfo: AddressInfo[],
    preferences: Preferences
}

export const toCustomerAddressPayload = (customerId: string, addressInfo: AddressInfo, currentStore: string): CustomerAddressPayload => {
    const deliveryGeocodes = addressInfo?.deliveryGeocodes?.find(dGeocode => {return dGeocode.isDefault === "Y"});
    const payload = {
        customerId: customerId,
        addressType: addressInfo?.addressType || 'Delivery',
        line1: addressInfo?.addressline1 || '',
        line2: addressInfo?.addressline2,
        city: addressInfo?.city || '',
        state: addressInfo?.state || '',
        zip: addressInfo?.zip || '',
        country: addressInfo?.country || 'USA',
        deliveryGeocodes: !!deliveryGeocodes ? [
            {
                pinLabel: deliveryGeocodes.pinLabel,
                latitude: deliveryGeocodes.latitude,
                longitude: deliveryGeocodes.longitude,
                pinId: deliveryGeocodes.pinId,
                isDefault: "Y"
            }
        ] : null,
        notes: addressInfo?.note,
        phoneNumber: !!addressInfo?.phoneNumber ? (addressInfo.phoneNumber.startsWith('1') ? addressInfo.phoneNumber : '1' + addressInfo.phoneNumber) : '',
        addressLabel: addressInfo?.addressLabel,
        latitude: addressInfo?.geocodes?.latitude,
        longitude: addressInfo?.geocodes?.longitude,
        isPreferredAddress: addressInfo?.isPreferredAddress
    } as CustomerAddressPayload
    if (!!addressInfo?.addressId) {
        payload.addressId = addressInfo?.addressId
        payload.updatedBy = currentStore
    }
    else {
        payload.createdBy = currentStore
    }
    return payload
}

export const formatPhoneNumber = (phone: string):string => {
    return phone.startsWith('1') ? `+1 (${phone.slice(1,4)}) ${phone.slice(4,7)}-${phone.slice(7)}` : `+1 (${phone.slice(0,3)}) ${phone.slice(3,6)}-${phone.slice(6)}`
}

export const formatARNumber = (arNumber: string):string => {
    return `#${arNumber}`
}

const formatDMS = (degress:number, minutes:number, seconds:number): string => {
    return `${Math.round(degress)}°${Math.round(minutes)}'${Math.round(seconds)}"`;
}
export const formatLatLang = (lat:number, long:number): string => {
    return `(${formatDMS(Math.floor(lat), (lat % 1) * 60, (lat % 1 * 60 % 1) * 60)}N, ${formatDMS(Math.floor(long), (long % 1) * 60, (long % 1 * 60 % 1) * 60)}W)`
}

export const americaStateList = [
    { value: "AL", label: "AL - Alabama" },
    { value: "AK", label: "AK - Alaska" },
    { value: "AZ", label: "AZ - Arizona" },
    { value: "AR", label: "AR - Arkansas" },
    { value: "CA", label: "CA - California" },
    { value: "CO", label: "CO - Colorado" },
    { value: "CT", label: "CT - Connecticut" },
    { value: "DE", label: "DE - Delaware" },
    { value: "DC", label: "DC - District Of Columbia" },
    { value: "FL", label: "FL - Florida" },
    { value: "GA", label: "GA - Georgia" },
    { value: "HI", label: "HI - Hawaii" },
    { value: "ID", label: "ID - Idaho" },
    { value: "IL", label: "IL - Illinois" },
    { value: "IN", label: "IN - Indiana" },
    { value: "IA", label: "IA - Iowa" },
    { value: "KS", label: "KS - Kansas" },
    { value: "KY", label: "KY - Kentucky" },
    { value: "LA", label: "LA - Louisiana" },
    { value: "ME", label: "ME - Maine" },
    { value: "MD", label: "MD - Maryland" },
    { value: "MA", label: "MA - Massachusetts" },
    { value: "MI", label: "MI - Michigan" },
    { value: "MN", label: "MN - Minnesota" },
    { value: "MS", label: "MS - Mississippi" },
    { value: "MO", label: "MO - Missouri" },
    { value: "MT", label: "MT - Montana" },
    { value: "NE", label: "NE - Nebraska" },
    { value: "NV", label: "NV - Nevada" },
    { value: "NH", label: "NH - New Hampshire" },
    { value: "NJ", label: "NJ - New Jersey" },
    { value: "NM", label: "NM - New Mexico" },
    { value: "NY", label: "NY - New York" },
    { value: "NC", label: "NC - North Carolina" },
    { value: "ND", label: "ND - North Dakota" },
    { value: "OH", label: "OH - Ohio" },
    { value: "OK", label: "OK - Oklahoma" },
    { value: "OR", label: "OR - Oregon" },
    { value: "PA", label: "PA - Pennsylvania" },
    { value: "PR", label: "PR - Puerto Rico" },
    { value: "RI", label: "RI - Rhode Island" },
    { value: "SC", label: "SC - South Carolina" },
    { value: "SD", label: "SD - South Dakota" },
    { value: "TN", label: "TN - Tennessee" },
    { value: "TX", label: "TX - Texas" },
    { value: "UT", label: "UT - Utah" },
    { value: "VT", label: "VT - Vermont" },
    { value: "VI", label: "VI - Virgin Islands" },
    { value: "VA", label: "VA - Virginia" },
    { value: "WA", label: "WA - Washington" },
    { value: "WV", label: "WV - West Virginia" },
    { value: "WI", label: "WI - Wisconsin" },
    { value: "WY", label: "WY - Wyoming" }
  ];

export const validateLatitude = (lat: number): number | null => {
    if (isNaN(lat)) return null;
    return (-90 <= lat && lat <= 90) ? lat : null
}

export const validateLongitude = (lng: number): number | null => {
    if (isNaN(lng)) return 106;
    return (-180 <= lng && lng <= 180) ? lng : null
}