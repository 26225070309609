import { createContext, ReactElement, useContext, useEffect, useState } from 'react'
import { AuthHelper, getApacUserType } from '../helpers/AuthHelper'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useUserAcceptedTerms } from '../../queries/Terms/useUserAcceptedTerms'
import { AuthContext as ApacAuthContext, IAuthContext } from 'react-oauth2-code-pkce'
import { isApac } from '../helpers/RegionHelper'
import { getApacUserByUserName, UserDataTypes } from '../services/ApacUserService/ApacUserService'
import { SnackbarContext } from './SnackbarContext'
import { LanguageContext } from './LanguageContext'
import language from '../../language/language'
import { UserManagementService } from '../services/UserManagementService'
import {
  getAccessTokenWithRefreshToken,
  getPingTokenByCode
} from '../services/PingAuthService/PingAuthService'
import {
  calculateExpireTime,
  clearCookieAndRedirectHome,
  getCOSFromStorage,
  setCOStoStorage
} from '../helpers/CosHelper'
import { CometChatUIKit } from '@cometchat/chat-uikit-react'

export const AuthContext = createContext<AuthContextType>({
  currentUser: null,
  isInitializingCurrentUser: true,
  isInitializingTerms: true,
  signIn: () => {},
  signOut: () => {},
  termsAccepted: undefined,
  refetchTerms: () => {},
  isCOS: false
})

type AuthContextType = {
  currentUser: CurrentUser | null
  isInitializingCurrentUser: boolean
  signIn: (username: string, password: string) => void
  signOut: () => void
  termsAccepted: boolean | undefined
  isInitializingTerms: boolean
  refetchTerms: () => void
  isCOS: boolean
}

export interface CurrentUser {
  username: string
  givenName: string
  familyName: string
  email: string
  defaultStoreNumber: string
  storeList: string[]
  userType: string
}

export const AuthProvider = ({ children }: any): ReactElement => {
  let navigate = useNavigate()
  const [searchParams] = useSearchParams()
  // const callOnce = useRef(true)
  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null)
  const [isInitializingCurrentUser, setIsInitializingCurrentUser] = useState<boolean>(true)
  const { token, logOut, loginInProgress, logIn } = useContext<IAuthContext>(ApacAuthContext)
  const {
    data: termsAccepted,
    isLoading: isLoadingTerms,
    refetch: refetchTerms
  } = useUserAcceptedTerms(currentUser?.username as string)
  const { addSnack } = useContext(SnackbarContext)
  const { currentLanguage } = useContext(LanguageContext)
  const [expireTime, setExpireTime] = useState<number>()
  const isCOS = getCOSFromStorage('isCOS')
  const isAuthenticated = getCOSFromStorage('authenticated')

  // Compare user details with ping and update the database accordingly
  const fetchAndCompareUserData = async (isCOS = false, cosUserUpdate = true) => {
    try {
      const pingUserData = await AuthHelper.currentSignedInApacUser(isCOS)
      try {
        const userFromDB: UserDataTypes = await getApacUserByUserName(pingUserData.username)
        const currentUser = {
          username: pingUserData.username,
          givenName: pingUserData.given_name,
          familyName: pingUserData.family_name,
          email: pingUserData.UserPrincipalName,
          defaultStoreNumber: userFromDB.defaultStoreNumber,
          userType: getApacUserType(pingUserData),
          storeList: userFromDB.storeList
        }
        // if user disabled then throw error otherwise set the user
        if (!userFromDB.enabled) {
          addSnack({
            severity: 'error',
            message: 'This account has been disabled. Please see store admin to re-enable it',
            duration: 3000
          })
          return Promise.reject()
        }

        if (isCOS && !isApac) {
          setCOStoStorage('authenticated', 'true')
        }
        setCurrentUser(currentUser)

        // It will call the user update endpoint if data mismatched with ping
        const { payload, isUpdate } = AuthHelper.compareApacUser(pingUserData, userFromDB)
        if (isUpdate && cosUserUpdate) {
          try {
            await UserManagementService.updateUser(payload, pingUserData.username)
          } catch (e) {
            console.error('Unable to update user', e)
          }
        }
      } catch (error: any) {
        addSnack({
          severity: 'error',
          message:
            error.status === 404
              ? (language as any)[currentLanguage].errorUserByUserName
              : error.response.data.message,
          duration: 3000
        })
      }
    } catch (e: any) {
      addSnack({
        severity: 'error',
        message: e.message,
        duration: 3000
      })
    } finally {
      setIsInitializingCurrentUser(false)
    }
  }

  // COS User
  const getCOSUserDetails = async (code: string) => {
    try {
      const response = await getPingTokenByCode(code)
      if (response.success === 'true') {
        fetchAndCompareUserData(isCOS)
        const time = calculateExpireTime(response.expires_in)
        setExpireTime(time)
      }
    } catch (e) {
      addSnack({
        severity: 'error',
        message: `Unable to get the token. ${e}`,
        duration: 3000
      })
    }
  }

  const refreshTheToken = async () => {
    try {
      const response = await getAccessTokenWithRefreshToken()
      if (response.success === 'true') {
        const time = calculateExpireTime(response.expires_in)
        setExpireTime(time)
      }
    } catch (e) {
      addSnack({
        severity: 'error',
        message: `Unable to refresh the token. ${e}`,
        duration: 3000
      })
    }
  }

  // get new access token using refresh token once it expired
  useEffect(() => {
    if (isAuthenticated && expireTime) {
      const timeoutId = setTimeout(() => {
        refreshTheToken()
      }, expireTime)
      return () => clearTimeout(timeoutId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, expireTime])

  useEffect(() => {
    const code = searchParams.get('code')
    if (isApac) {
      if (!loginInProgress && !token) logIn()
      if (!loginInProgress && token) {
        fetchAndCompareUserData()
      }
    } else if (isAuthenticated || code) {
      if (isAuthenticated) {
        fetchAndCompareUserData(isCOS, false)
      }
      // Please enable this callOnce for local run as API call twice due to react strict
      // if (callOnce.current && code) {
      //   callOnce.current = false
      if (code) {
        getCOSUserDetails(code)
      }
    } else {
      AuthHelper.currentSignedInUser()
        .then((user) => {
          setCurrentUser(user)
        })
        .catch((e) => console.error(e.message))
        .finally(() => setIsInitializingCurrentUser(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, loginInProgress])

  useEffect(() => {
    if (!currentUser) return
    const cometChatLogin = async () => {
      //TODO: maybe move this to sign-out?
      const user = await CometChatUIKit.getLoggedinUser()
      if (user) {
        await CometChatUIKit.logout()
      }
      const userInfo = await getApacUserByUserName(currentUser.username)

      await CometChatUIKit.login(userInfo.cometChatUUID)
      console.log('Successfully Logged in to CometChat.')
    }

    cometChatLogin()
      .catch((e) => {
        console.log(e)
      })
  }, [currentUser])

  const signIn = async (username: string, password: string) => {
    try {
      const currentUser: CurrentUser = await AuthHelper.signIn({ username, password })
      setCurrentUser(currentUser)
      navigate('/')
    } catch (error) {
      console.error(error)
      return Promise.reject(error)
    }
  }

  const signOut = async () => {
    if (isApac) {
      setCurrentUser(null)
      logOut()
    } else if (isCOS) {
      setIsInitializingCurrentUser(true)
      try {
        await AuthHelper.signOutCOSUser()
        setCurrentUser(null)
        clearCookieAndRedirectHome()
      } catch (e: any) {
        addSnack({
          severity: 'error',
          message: e.message,
          duration: 3000
        })
      } finally {
        setIsInitializingCurrentUser(false)
      }
    } else {
      try {
        await AuthHelper.signOut()
      } catch (error: any) {
        console.error('Error signing out')
      } finally {
        setCurrentUser(null)
        navigate('/')
      }
    }
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        isInitializingCurrentUser,
        isInitializingTerms: isLoadingTerms,
        signOut,
        signIn,
        termsAccepted,
        refetchTerms,
        isCOS
      }}>
      {children}
    </AuthContext.Provider>
  )
}
