import React, { useEffect, useState, useContext } from 'react'
import Grid from '@mui/material/Grid'
import UserCard from './components/UserCard/UserCard'
import { Box, CircularProgress, Skeleton, Typography, Link } from '@mui/material'
import whiteboardStyles from './whiteboardStyles'
import { useUsersByStore } from '../../queries/Users/useUsersByStore'
import { useActiveDrivers } from '../../queries/Users/useActiveDrivers'
import { useNavigate } from 'react-router-dom'
import { AppUser, Driver } from '../../utilities/services/UserManagementService'
import { DeliveryRoute } from '../../utilities/types/DeliveryRouteTypes'
import Colors from '../../assets/Colors'
import MapView from '../MapView/MapView'
import WhiteboardToggleMap from './components/WhiteboardToggleMap/WhiteboardToggleMap'
import language from '../../language/language'
import { LanguageContext } from '../../utilities/contexts/LanguageContext'

const Whiteboard = () => {
  //commnetd out the code to not display the inactive driver's user card
  // const [inactiveUsers, setInactiveUsers] = useState<any[]>([])
  const [activeUsers, setActiveUsers] = useState<any[]>([])
  const [isMapViewActive, setIsMapViewActive] = useState(true); // State to track the active view
  const { data: storeDrivers } = useUsersByStore()
  let navigate = useNavigate()
  const { currentLanguage } = useContext(LanguageContext)

  const {
    data: activeDrivers,
    isLoading: isLoadingActiveDrivers,
    isFetching: isFetchingActiveDrivers
  } = useActiveDrivers()

  const toggleView = () => {
    if (!isMapViewActive) {
    }
    setIsMapViewActive(!isMapViewActive)
  }

  useEffect(() => {
    const sortDrivers = (activeDriverList: Driver[], storeDriversList: AppUser[]) => {
      activeDriverList.forEach((driver) => {
        driver.deliveryRoutes = driver.deliveryRoutes.filter((route) => route.stops.length > 0)
      })
      activeDriverList = activeDriverList.filter(
        (driver) => driver && driver.deliveryRoutes.length > 0 && driver.enabled
      )
      const findFirstActive = (routes: DeliveryRoute[]) => {
        return (
          routes.find(
            (route) => route.routeStatus === 'in-progress' || route.routeStatus === 'pending'
          ) || { routeCreationTimestamp: 9999999999999999999999999999999999999999 }
        )
      }
      const inProgressRoutes = (routes: DeliveryRoute[]) => {
        let inProgress = false
        routes.forEach((route) => {
          if (route.routeStatus === 'in-progress') {
            inProgress = true
          }
        })
        return inProgress ? 1 : -1
      }
      activeDriverList.sort(
        (a, b) =>
          findFirstActive(a.deliveryRoutes).routeCreationTimestamp -
          findFirstActive(b.deliveryRoutes).routeCreationTimestamp
      )
      activeDriverList.sort(
        (a, b) => inProgressRoutes(b.deliveryRoutes) - inProgressRoutes(a.deliveryRoutes)
      )
      setActiveUsers(activeDriverList)

      // const activeNames = activeDriverList.map((driver) => driver.username)

      // let sortedInactiveDrivers = storeDriversList.filter(
      //   (driver) => !activeNames.includes(driver.username)
      // )

      // sortedInactiveDrivers = sortedInactiveDrivers.filter((driver) => driver && driver.enabled)
      // setInactiveUsers(sortedInactiveDrivers)
    }
    if (storeDrivers && activeDrivers && activeDrivers.length > 0) {
      sortDrivers(activeDrivers, storeDrivers)
    }
    else {
      setActiveUsers([])
    }
    //  else {
    //   if (storeDrivers) {
    //     setInactiveUsers(storeDrivers)
    //   }
    // }
  }, [storeDrivers, activeDrivers])

  const renderSkeleton = () => {
    let i = 0
    const skeletons = []
    while (i < 6) {
      i++
      skeletons.push(
        <Grid item xs={4} sm={3} key={i}>
          <Skeleton variant={'rectangular'} height={201} width={263} animation={'wave'} />
        </Grid>
      )
    }
    return skeletons
  }

  return (
    <Box style={whiteboardStyles.whiteboardContainer}>
      {!isMapViewActive && (
        <Grid sx={whiteboardStyles.WhiteboardMap}>
          <MapView activeUsers={activeUsers} />
        </Grid>
      )}
      <Typography variant="h4" style={whiteboardStyles.whiteboardHeader}>
      {(language as any)[currentLanguage].whiteboard} {isFetchingActiveDrivers ? <CircularProgress size={14} /> : null} 
      </Typography>
      <Grid style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '-57px', position: 'relative', zIndex: 2, marginRight: '25px' }}>
        <WhiteboardToggleMap
          isMapViewActive={isMapViewActive}
          toggleListViewSwitch={toggleView}
          toggleMapView={toggleView}
        />
      </Grid>
      {isMapViewActive && (
        <Grid>
          <div style={{ display: 'flex', flexDirection: 'row', overflowY: 'auto' }}>
            {isLoadingActiveDrivers
              ? renderSkeleton()
              : activeUsers.length > 0
                ? activeUsers.map((driver, idx) => {
                  return (
                    <UserCard
                      isLoadingDrivers={isLoadingActiveDrivers}
                      user={driver}
                      routeData={driver.deliveryRoutes}
                      key={idx}
                    />
                  )
                })
                :
                <Box margin='auto' paddingTop={18}>
                  <Typography color={Colors.napaGrey3} style={whiteboardStyles.noActiveRoutesText1}>{(language as any)[currentLanguage].zeroRoutes}</Typography>
                  <Typography color={Colors.napaGrey3} style={whiteboardStyles.noActiveRoutesText2}>Click&nbsp;
             <Link color={Colors.napaBlueLink} href="#" onClick={() => navigate('/RouteBuilder')}>
                      {(language as any)[currentLanguage].routeBuilder}
                    </Link>&nbsp;{(language as any)[currentLanguage].buildRoute}</Typography>
                </Box>}

            {/* {inactiveUsers
         ? inactiveUsers.map((driver, idx) => {
             return (
               <UserCard
                 isLoadingDrivers={isLoadingActiveDrivers}
                 user={driver}
                 routeData={[]}
                 key={idx}
               />
             )
           })
         : renderSkeleton()} */}
          </div>
        </Grid>
      )}
    </Box>
  )
}

export default Whiteboard
